import React, { useState } from "react";
import { Button, CardBody, Card, CardHeader, CardFooter, UncontrolledPopover, PopoverBody } from "reactstrap";
import { Clock24, Bullet16 } from "@bphxd/ds-core-react/lib/icons";

import "../../Content/stylesheet/traineeassessment.css";
import CriticalIcon from "../../Content/Images/Icons/critical-hazard.png";

import ConfirmAssessmentSubmitToast from "../Toast/ConfirmAssessmentSubmitToast";
import { useTranslation } from "react-i18next";

const ReviewQuestions = (props) => {
  const { t } = useTranslation();
  const { questionsData, timeRemaining, assessmentType } = props;

  let unAnsweredQuestions = [];

  questionsData.forEach((question, index) => {
    if (question.selectedAnswers === "") unAnsweredQuestions.push(index + 1);
  });

  unAnsweredQuestions = unAnsweredQuestions.join(", ");

  const [showToast, setShowToast] = useState({ name: "", show: false });

  const submitMessage = (
    <div className="">
      <div>
        {t("MyTrainingpage.submit1")}
      </div>
      {unAnsweredQuestions !== "" && (
        <div>
          <div>
            {t("MyTrainingpage.Res1")}{" "}
            {unAnsweredQuestions.length > 1 ? "questions:" : "question:"}
          </div>
          <div className="pt-2">{`${unAnsweredQuestions}`}</div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {showToast.name === "submitAssessment" && (
        <ConfirmAssessmentSubmitToast
          className={"trainee-submitpopup"}
          show={showToast.show}
          handleCancel={() => setShowToast({ name: "", show: false })}
          handleDelete={() => {
            props.submitAssessment();
            setShowToast({ name: "submitAssessment", show: false });
          }}
          Message={submitMessage}
        />
      )}
      <div className="container-fluid " style={{ marginBlock: "12px" }}>
        <div className="container trainee-cardcontainer">
          <Card className="trainee-card">
            <CardHeader className="pt-4 fs-5 pb-4 ">
              <div className="review-description">
                {t("MyTrainingpage.Review Responses")}
              </div>
            </CardHeader>
            <CardBody>
              <div className="card-reviewbodycontainer">
                {questionsData.map((question, index) => {
                  const answersSelected = question.selectedAnswers
                    .toString()
                    .replaceAll(/ /g, "")
                    .split(";");

                  const isOptionASelected = answersSelected.includes("A");
                  const isOptionBSelected = answersSelected.includes("B");
                  const isOptionCSelected = answersSelected.includes("C");
                  const isOptionDSelected = answersSelected.includes("D");

                  const isQuestionAnswered =
                    isOptionASelected === true ||
                    isOptionBSelected === true ||
                    isOptionCSelected === true ||
                    isOptionDSelected === true;

                  const isQuestionCritical = question.isCriticalQuestion;
                  return (
                    <div className="reviewqa-parentcontainer" key={`question-${index}`}
                    >
                      <div className="queans__maincontainer">
                        <div
                          className={
                            isQuestionAnswered
                              ? "que-numberindicator"
                              : "que-numberindicator noresponse-textcolor"
                          }
                        >
                          {`${index + 1}.`}
                        </div>
                        <div className="reviewresponse-container">
                          <div
                            className={
                              isQuestionAnswered
                                ? "reviewque-description"
                                : "reviewque-description noresponse-textcolor"
                            }
                          >
                            {question.question_Description}
                          </div>

                          {isQuestionAnswered ? (
                            <div>
                              {t("MyTrainingpage.Your Responses")}:
                            </div>
                          ) : (
                            <div className="noresponse-textcolor">
                              {t("MyTrainingpage.No Response")}
                            </div>
                          )}

                          <div className="reviewque__answerscontainer">
                            {question.optionA != null &&
                              question.optionA !== "" &&
                              isOptionASelected ? (
                              <div className="reviewanswer-container">
                                <div>
                                  <Bullet16 />
                                </div>
                                <div className="answer__description">
                                  <label htmlFor={`${question.fk_ID}-review-A`}>
                                    {question.optionA}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {question.optionB != null &&
                              question.optionB !== "" &&
                              isOptionBSelected ? (
                              <div className="reviewanswer-container">
                                <div>
                                  <Bullet16 />
                                </div>
                                <div className="answer__description">
                                  <label htmlFor={`${question.fk_ID}-review-B`}>
                                    {question.optionB}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {question.optionC != null &&
                              question.optionC !== "" &&
                              isOptionCSelected ? (
                              <div className="reviewanswer-container">
                                <div>
                                  <Bullet16 />
                                </div>
                                <div className="answer__description">
                                  <label htmlFor={`${question.fk_ID}-review-C`}>
                                    {question.optionC}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {question.optionD != null &&
                              question.optionD !== "" &&
                              isOptionDSelected ? (
                              <div className="reviewanswer-container">
                                <div>
                                  <Bullet16 />
                                </div>
                                <div className="answer__description">
                                  <label htmlFor={`${question.fk_ID}-review-D`}>
                                    {question.optionD}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="reviewque__changeresponse-container">
                            <Button
                              className="rounded-pill btn-style-fit"
                              color="standard-secondary"
                              size="sm"
                              onClick={() => props.onClickResponse(index)}
                            >
                              {t("MyTrainingpage.Change Response")}
                            </Button>
                            {assessmentType === "TAR" && isQuestionCritical && (
                              <div
                                className="critical-que-indicator"
                                style={{ alignSelf: "center" }}
                              >
                                <img
                                  id={question.fk_ID}
                                  src={CriticalIcon}
                                  alt="Critical Question"
                                  className="critical-icon"
                                />
                                <UncontrolledPopover trigger="hover focus" placement="left" target={question.fk_ID}>
                                  <PopoverBody>{t("MyTrainingpage.InfoMyT")}</PopoverBody>
                                </UncontrolledPopover>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </CardBody>

            <CardFooter className="">
              <div className="queans-footercontainer pt-2 pb-2">
                <div className="footer__quenotimercontainer">
                  <div className="qa__timeritem">
                    <Clock24 className="timer__icon" />
                    <div className="timer__timetext">
                      {timeRemaining.minutes < 10
                        ? `0${timeRemaining.minutes}`
                        : timeRemaining.minutes}
                      :
                      {timeRemaining.seconds < 10
                        ? `0${timeRemaining.seconds}`
                        : timeRemaining.seconds}
                    </div>
                  </div>
                </div>
                <div className="que__btncontainer">
                  <Button
                    className="rounded-pill btn-style"
                    color="standard-primary"
                    size="sm"
                    onClick={() => {
                      setShowToast({ name: "submitAssessment", show: true });
                    }}
                  >
                    {t("MyTrainingpage.Submit")}
                  </Button>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ReviewQuestions;
