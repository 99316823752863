import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en-GB",
  lng: "en-GB",
  resources: {
    "en-GB": {
      translations: require("./locales/en/translations.json"),
    },
    "pt-PT": {
      translations: require("./locales/pt/translations.json"),
    },
    "pt-BR": {
      translations: require("./locales/br/translations.json"),
    },
    "fr-FR": {
      translations: require("./locales/fr/translations.json"),
    },
    "fi-FI": {
      translations: require("./locales/fi/translations.json"),
    },
    "sv-SE": {
      translations: require("./locales/se/translations.json"),
    },
    "no-NO": {
      translations: require("./locales/no/translations.json"),
    },
    "el-GR": {
      translations: require("./locales/el/translations.json"),
    }
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en-GB", "pt-PT", "fr-FR", "fi-FI"];

export default i18n;
